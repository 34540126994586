@import '~@fullcalendar/core/main.css';
 @import '~@fullcalendar/daygrid/main.css';
 @import '~@fullcalendar/timegrid/main.css';
 .demo-app {
	 font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
	 font-size: 14px;
}
 .demo-app-top {
	 margin: 0 0 3em;
}
 .demo-app-calendar {
	 margin: 0 auto;
}
 .fc-body {
	 box-shadow: 0px 3px 6px #000 29 !important;
}
 .fc-unthemed th, .fc-unthemed td, .fc-unthemed thead, .fc-unthemed tbody, .fc-unthemed .fc-divider, .fc-unthemed .fc-row, .fc-unthemed .fc-content, .fc-unthemed .fc-popover, .fc-unthemed .fc-list-view, .fc-unthemed .fc-list-heading td {
	 border-color: rgba(64, 87, 106, 0.1);
}
 .fc-day {
	 background: #fff;
}
 .fc-unthemed th, .fc-unthemed thead, .fc-widget-header, .fc-head {
	 border: 0 !important;
	 background: transparent !important;
}
 .fc-unthemed th.fc-widget-header {
	 text-transform: uppercase;
	 font-weight: normal;
	 font-size: 16px;
	 color: #40576a;
	 padding: 25px 0;
	 text-align: left;
	 text-indent: 5px;
}
 .fc-ltr .fc-dayGrid-view .fc-day-top .fc-day-number {
	 float: left;
	 font-weight: bold;
	 color: #1773bf;
	 font-size: 18px;
	 margin: 10px;
	 padding: 0;
}
 .fc-toolbar.fc-header-toolbar h2 {
	 color: #17baff;
	 font-size: 24px;
	 text-transform: uppercase;
}
 .fc-ltr .fc-dayGrid-view .fc-day-top.fc-today .fc-day-number {
	 background: #20a6fc;
	 border-radius: 8px;
	 color: #fff;
	 padding: 6px 8px;
	 box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
 .fc-event {
	 border-radius: 20px;
}
 .fc-day-grid-event {
	 padding: 0 6px;
	 margin: 1px 8px 0px;
}
 .fc-toolbar.fc-header-toolbar {
	 display: inline-block;
	 width: 100%;
}
 .fc-left {
	 float: right;
	 margin-left: 20px;
}
 .fc-center {
	 float: left;
}
 .fc-right {
	 float: right;
}
 .fc-button-primary {
	 background-color: #349aff;
	 border-color: #0f91e4;
}
 .fc-button-primary:not(:disabled):active, .fc-button-primary:not(:disabled).fc-button-active, .fc-button-primary:hover, .fc-button-primary:disabled {
	 border-color: #0f91e4;
	 background-color: #20a6fc;
}
 .fc-future, .fc-today {
	 position: relative;
}
 .fc-future:hover:before, .fc-today:hover:before {
	 content: '';
	 height: 20px;
	 width: 20px;
	 display: inline-block;
	 position: absolute;
	 right: 10px;
	 top: 10px;
	 z-index: 9;
     background-image: url("download.svg");
}
 .fc-other-month.fc-future, .fc-other-month.fc-past {
	 background-color: #eaeef0 !important;
}
 .fc-other-month:hover:before {
	 display: none !important;
}
 .fc-day-grid .fc-future {
	 cursor: pointer;
}
 .fc-day-grid .fc-today {
	 cursor: pointer;
}
 .fc-day-grid .fc-content {
	 cursor: pointer;
}
 